export const Constants = Object.freeze({
    'WALKTHROUGHT': 'Walkthrought',
    'SIGNUP': 'Signup',
    'SOCIAL': 'Social',
    'PROFILE': 'Profile',
    'MEDIA': 'Media',
    'E_COMMERCE': 'E-Commerce',
    'CHECK_BUTTON': 'Check Box',
    'TOGGLE_BUTTON': 'Toggle Button',
    'RADIO_BUTTON': 'Radio Button',
    'LISTS': 'Lists',
    'OTHER': 'Other',
    'RANGE': 'Range',
    'TAB': 'Tab'
});
