import { Injectable } from '@angular/core';
import { IService } from './IService';
import { MenuItem } from './MenuItem';
import { Constants } from './constants';
import { ToastService } from './toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class SignUpService implements IService {

  constructor(private toastCtrl: ToastService, private loadingService: LoadingService, public af: AngularFireDatabase) { }

  getMenuList(): MenuItem[] {
    var menuItemList = [];
    menuItemList.push(new MenuItem(1, 'Login', 0, Constants.SIGNUP));
    menuItemList.push(new MenuItem(2, 'SignUp', 0, Constants.SIGNUP));
    menuItemList.push(new MenuItem(3, 'Reset Password', 0, Constants.SIGNUP));
    menuItemList.push(new MenuItem(4, 'Login / Welcome messaga', 0, Constants.SIGNUP));
    menuItemList.push(new MenuItem(5, 'Signup / Welcome messaga', 0, Constants.SIGNUP));
    menuItemList.push(new MenuItem(6, 'Reset Password / Welcome messaga', 0, Constants.SIGNUP));
    return menuItemList;
  }


  // DATA FOR LOGIN
  getDataForLayout1 = (): any => {
    return {
      'headerTitle': 'Login',
      'title': 'Login',
      'subTitle': 'Deco UI Kit',
      'labelUsername': 'Email Address',
      'labelPassword': 'Password',
      'errorUser': 'Email Address is not Valid',
      'errorPassword': 'Password is not Valid',
      'loginButton': 'Login',
      'signup': 'Signup',
      'resetPassword': 'Reset Password',
    };
  };

  // DATA FOR SIGNUP
  getDataForLayout2 = (): any => {
    return {
      'headerTitle': 'SignUp',
      'title': 'Signup',
      'subTitle': 'Deco UI Kit',
      'labelName': 'Name',
      'labelLastName': 'Last Name',
      'labelPassword': 'Password',
      'labelEmail': 'Email Address',
      'signupButton': 'Signup',
      'login': 'Login',
    };
  };

  // DATA FOR RESET PASSWORD
  getDataForLayout3 = (): any => {
    return {
      'headerTitle': 'Reset Password',
      'title': 'Reset Password',
      'subTitle': 'Deco UI Kit',
      'labelOldPassword': 'Old Password',
      'labelNewPassword': 'New Password',
      'resetButton': 'Resete Password',
      'signup': 'Signup',
    };
  };

  // DATA FOR LOGIN WELCOME MASSAGE
  getDataForLayout4 = (): any => {
    return {
      'headerTitle': 'Login / Welcome messaga',
      'title': 'Login',
      'subTitle': 'Deco UI Kit',
      'description': 'Hello, let’s get started!',
      'labelUsername': 'Email Address',
      'labelPassword': 'Password',
      'errorUser': 'Email Address is not Valid',
      'errorPassword': 'Password is not Valid',
      'loginButton': 'Login',
      'signup': 'Signup',
      'resetPassword': 'Reset Password',
    };
  };

  // DATA FOR SIGNUP WITH MASSAGE
  getDataForLayout5 = (): any => {
    return {
      'headerTitle': 'Signup / Welcome messaga',
      'title': 'Signup',
      'subTitle': 'Deco UI Kit',
      'description': 'Hello, let’s get started!',
      'labelName': 'Name',
      'labelLastName': 'Last Name',
      'labelPassword': 'Password',
      'labelEmail': 'Email Address',
      'signupButton': 'Signup',
      'login': 'Login',
    };
  };


  // DATA FOR RESET PASSWORD WITH MASSAGE
  getDataForLayout6 = (): any => {
    return {
      'headerTitle': 'Reset Password / Welcome messaga ',
      'title': 'Reset Password',
      'subTitle': 'Deco UI Kit',
      'description': 'Hello, let’s get started!',
      'labelOldPassword': 'Old Password',
      'labelNewPassword': 'New Password',
      'resetButton': 'Resete Password',
      'signup': 'Signup',
    };
  };

  load(menuItem: MenuItem): Observable<any> {
    var that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('signup/layout' + menuItem.id)
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getDataByMenuId(menuItem));
        observer.complete();
      });
    }
  }

  getDataByMenuId(menuItem: MenuItem) {
    switch (menuItem.id) {
      case 1:
        return this.getDataForLayout1();

      case 2:
        return this.getDataForLayout2();

      case 3:
        return this.getDataForLayout3();

      case 4:
        return this.getDataForLayout4();

      case 5:
        return this.getDataForLayout5();

      case 6:
        return this.getDataForLayout6();
    }
  }

  loadEvents(menuItem: MenuItem) {
    var that = this;
    return {
      'onLogin': function (item: any) {
        that.toastCtrl.presentToast('Login');
      },
      'onResetPassword': function (item: any) {
        that.toastCtrl.presentToast('Reset Password');
      },
      'onSignUp': function (item: any) {
        that.toastCtrl.presentToast('Signup');
      },
      'onRegister': function (item: any) {
        that.toastCtrl.presentToast('Register');
      }
    };
  }
}
