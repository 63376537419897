import { Injectable } from '@angular/core';
import { IService } from './IService';
import { MenuItem } from './MenuItem';
import { Constants } from './constants';
import { ToastService } from './toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class MediaService implements IService {

  constructor(public af: AngularFireDatabase, private loadingService: LoadingService, private toastCtrl: ToastService) { }

  getMenuList(): MenuItem[] {
    var menuItemList = [];
    menuItemList.push(new MenuItem(1, 'List Videos', 0, Constants.MEDIA));
    menuItemList.push(new MenuItem(2, 'Album Photos', 0, Constants.MEDIA));
    menuItemList.push(new MenuItem(3, 'All Albums', 0, Constants.MEDIA));
    menuItemList.push(new MenuItem(4, 'Single Image', 0, Constants.MEDIA));
    return menuItemList;
  }


  // DATA FOR MEDIA --- LIAT VIDEOS
  getDataForDesign1 = (): any => {
    return {
      'headerTitle': 'List Videos',
      'items': [
        {
          'id': 1,
          'images': 'assets/imgs/content/1.jpg',
          'title': 'Pink Classic Chair',
          'time': 'Dusty pink chair',
          'icon': 'play'
        },
        {
          'id': 2,
          'images': 'assets/imgs/content/2.jpg',
          'title': 'Deep Sea Jellyfish',
          'time': 'Jelly fish or sea jelly',
          'icon': 'play'
        },
        {
          'id': 3,
          'images': 'assets/imgs/content/3.jpg',
          'title': 'Floating Pink Cherry',
          'time': 'Cherry is the fruit',
          'icon': 'play'
        },
        {
          'id': 4,
          'images': 'assets/imgs/content/4.jpg',
          'title': 'Green Leaf Pattern',
          'time': 'World or manmade design',
          'icon': 'play'
        },
        {
          'id': 5,
          'images': 'assets/imgs/content/5.jpg',
          'title': 'Trees and Blue River',
          'time': 'Hiking, biking and walking',
          'icon': 'play'
        },
        {
          'id': 6,
          'images': 'assets/imgs/content/6.jpg',
          'title': 'Pink Jellyfish in Sea',
          'time': 'Eaten by humans',
          'icon': 'play'
        },
        {
          'id': 7,
          'images': 'assets/imgs/content/7.jpg',
          'title': 'Orange Stairs Building',
          'time': 'Open stair effect',
          'icon': 'play'
        },
        {
          'id': 8,
          'images': 'assets/imgs/content/8.jpg',
          'title': 'Color full Tennis Court',
          'time': 'Rocket sport',
          'icon': 'play'
        },
        {
          'id': 9,
          'images': 'assets/imgs/content/9.jpg',
          'title': 'Green Vegetables Pattern',
          'time': 'Parts of plants',
          'icon': 'play'
        },
        {
          'id': 10,
          'images': 'assets/imgs/content/10.jpg',
          'title': 'Abstract Blue Wallpaper',
          'time': 'Abstract summary',
          'icon': 'play'
        }
      ]
    }
  }

  // DATA FOR MEDIA --- ALBUM PHOTOS
  getDataForDesign2 = (): any => {
    return {
      'headerTitle': 'Album Photos',
      /* Gallery */
      'gallery': [
        'assets/imgs/content/1.jpg',
        'assets/imgs/content/2.jpg',
        'assets/imgs/content/3.jpg',
        'assets/imgs/content/4.jpg',
        'assets/imgs/content/5.jpg',
        'assets/imgs/content/6.jpg',
        'assets/imgs/content/7.jpg',
        'assets/imgs/content/8.jpg',
        'assets/imgs/content/9.jpg',
        'assets/imgs/content/10.jpg'
      ]
    }
  }

  // DATA FOR MEDIA --- ALL ALBUMS
  getDataForDesign3 = (): any => {
    return {
      'headerTitle': 'Photo Albums',
      'items': [
        {
          'id': 1,
          'images': 'assets/imgs/content/1.jpg',
          'title': 'Pink Classic Chair',
          'numberPhoto': 'March 14th, 2019',
        },
        {
          'id': 2,
          'images': 'assets/imgs/content/2.jpg',
          'title': 'Deep Sea Jellyfish',
          'numberPhoto': 'March 19th, 2019',
        },
        {
          'id': 3,
          'images': 'assets/imgs/content/3.jpg',
          'title': 'Floating Pink Cherry',
          'numberPhoto': 'April 27th, 2019',
        },
        {
          'id': 4,
          'images': 'assets/imgs/content/4.jpg',
          'title': 'Green Leaf Pattern',
          'numberPhoto': 'June 15th, 2019',
        },
        {
          'id': 5,
          'images': 'assets/imgs/content/5.jpg',
          'title': 'Trees and Blue River',
          'numberPhoto': 'August 25th, 2019',
        },
        {
          'id': 6,
          'images': 'assets/imgs/content/6.jpg',
          'title': 'Pink Jellyfish in Sea',
          'numberPhoto': 'April 22th, 2019',
        },
        {
          'id': 7,
          'images': 'assets/imgs/content/7.jpg',
          'title': 'Orange Stairs Building',
          'numberPhoto': 'July 14th, 2019',
        },
        {
          'id': 8,
          'images': 'assets/imgs/content/8.jpg',
          'title': 'Color full Tennis Court',
          'numberPhoto': 'January 29th, 2019',
        },
        {
          'id': 9,
          'images': 'assets/imgs/content/9.jpg',
          'title': 'Green Vegetables Pattern',
          'numberPhoto': 'April 17th, 2019',
        },
        {
          'id': 10,
          'images': 'assets/imgs/content/10.jpg',
          'title': 'Abstract Blue Wallpaper',
          'numberPhoto': 'May 12th, 2019',
        }
      ]
    }
  }

  // DATA FOR MEDIA --- SINGLE IMAGE
  getDataForDesign4 = (): any => {
    return {
      'headerTitle': 'Album Photos',
      'image': 'assets/imgs/content/9.jpg',
    }
  }


  load(menuItem: MenuItem): Observable<any> {
    var that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('media/layout' + menuItem.id)
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getDataByMenuId(menuItem));
        observer.complete();
      });
    }
  }

  loadEvents(menuItem: MenuItem) {
    return this.geEventsByMenuId(menuItem);
  }

  getDataByMenuId(menuItem: MenuItem) {
    switch (menuItem.id) {
      case 1:
        return this.getDataForDesign1();

      case 2:
        return this.getDataForDesign2();

      case 3:
        return this.getDataForDesign3();

      case 4:
        return this.getDataForDesign4();
    }

  }

  geEventsByMenuId(menuItem: MenuItem): any {
    switch (menuItem.id) {
      case 1:
        return this.getEventsForDesign1();

      case 2:
        return this.getEventsForDesign2();

      case 3:
        return this.getEventsForDesign3();

      case 4:
        return this.getEventsForDesign4();

    }
  }

  getEventsForDesign1(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);

      }
    };
  }

  getEventsForDesign2(): any {
    return {};
  }

  getEventsForDesign3(): any {
    var that = this;
    return {
      'onItemClick': function (item) {
        that.toastCtrl.presentToast(item.title);
      }
    };
  }

  getEventsForDesign4(): any {
    return {};
  }
}
