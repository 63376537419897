import { Injectable } from '@angular/core';
import { IService } from './IService';
import { MenuItem } from './MenuItem';
import { Constants } from './constants';
import { ToastService } from './toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class EcommerceService implements IService {

  constructor(private toastCtrl: ToastService, private loadingService: LoadingService, public af: AngularFireDatabase) { }

  getMenuList(): MenuItem[] {
    var menuItemList = [];
    menuItemList.push(new MenuItem(1, 'Shop with Card', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(2, 'Product Color', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(3, 'Product Size', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(4, 'Product Type', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(5, 'Filters List', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(6, 'Filters', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(7, 'Shoping Cart', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(8, 'Product Big Image', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(9, 'Shop with Slider', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(10, 'Shop with Cover', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(11, 'Checkout with Total amount', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(12, 'Paymentt', 0, Constants.E_COMMERCE));
    menuItemList.push(new MenuItem(13, 'Congratulations', 0, Constants.E_COMMERCE));
    return menuItemList;
  }

  // DATA FOR E-COMMERCE --- SHOP WITH CARD
  getDataForDesign1 = (): any => {
    return {
      'headerTitle': 'Shop with Cards',
      'bigTitle': 'New Products',
      'items': [
        {
          'id': 1,
          'images': 'assets/imgs/shop/1.jpg',
          'title': 'Brown Woman bag',
          'price': '$ 26.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart'
        },
        {
          'id': 2,
          'images': 'assets/imgs/shop/2.jpg',
          'title': 'Green Man T-Shirt',
          'price': '$ 36.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
        {
          'id': 3,
          'images': 'assets/imgs/shop/3.jpg',
          'title': 'Orange Football Man',
          'price': '$ 65.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
        {
          'id': 4,
          'images': 'assets/imgs/shop/4.jpg',
          'title': 'Classic Brown Hat',
          'price': '$ 36.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
        {
          'id': 5,
          'images': 'assets/imgs/shop/5.jpg',
          'title': 'Blue Classic Shirt',
          'price': '$ 22.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart'
        },
        {
          'id': 6,
          'images': 'assets/imgs/shop/6.jpg',
          'title': 'Big Orange Backpack',
          'price': '$ 46.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart'
        },
        {
          'id': 7,
          'images': 'assets/imgs/shop/7.jpg',
          'title': 'Colorfufl Hiphop Hat',
          'price': '$ 71.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
        {
          'id': 8,
          'images': 'assets/imgs/shop/8.jpg',
          'title': 'Blue Man Shorts',
          'price': '$ 66.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
        {
          'id': 9,
          'images': 'assets/imgs/shop/9.jpg',
          'title': 'Red Woman Shoes',
          'price': '$ 32.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
        {
          'id': 10,
          'images': 'assets/imgs/shop/10.jpg',
          'title': 'Classic Green Bag',
          'price': '$24.95',
          'icon': 'basket',
          'iconTitle': 'Add to Cart',
        },
      ]
    }
  }

  // DATA FOR E-COMMERCE --- PRODUCT COLOR
  getDataForDesign2 = (): any => {
    return {
      'headerTitle': ' Product Color',
      'productImage': 'assets/imgs/shop/1.jpg',
      'productTitle': 'Brown Woman bag',
      'productSubtitle': 'Short Fitness Leggings for Woman',
      'description': 'description',
      'descriptionShort': 'The public is more familiar with bad design than good design. It is, in effect, conditioned to prefer bad design, because that is what it lives with. The new becomes threatening.',
      'items': [
        {
          'id': 1,
          'shopImage': 'assets/imgs/shop/2.jpg',
          'shopTitle': 'Green Man T-Shirt',
          'shopSubtitle': 'T-Shirt',
          'shopPrice': '$ 14.89',
          'shopIcon': 'basket',
        },
        {
          'id': 2,
          'shopImage': 'assets/imgs/shop/3.jpg',
          'shopTitle': 'Orange Football Mant',
          'shopSubtitle': 'Mant',
          'shopPrice': '$ 29.89',
          'shopIcon': 'basket',
        },
        {
          'id': 3,
          'shopImage': 'assets/imgs/shop/4.jpg',
          'shopTitle': 'Classic Brown Hat',
          'shopSubtitle': 'Hat',
          'shopPrice': '$ 75.10',
          'shopIcon': 'basket',
        }
      ],

      'button': 'Add to Cart'
    }
  }

  // DATA FOR E-COMMERCE --- PRODUCT SIZE
  getDataForDesign3 = (): any => {
    return {
      'headerTitle': 'Product Size',
      'productName': 'Brown Woman bag',
      'productDescription': 'The public is more familiar with bad design than good design. It is, in effect, conditioned to prefer bad design, because that is what it lives with',
      'productImage': 'assets/imgs/shop/1.jpg',
      'button': 'Add to Cart',
      'size': {
        'size': 'Size',
        'number1': '32',
        'number2': '33',
        'number3': '34',
        'number4': '35',
      },
    }
  }


  // DATA FOR E-COMMERCE --- PRODUCT TYPE
  getDataForDesign4 = (): any => {
    return {
      'headerTitle': 'Product Type',
      'productImage': 'assets/imgs/shop/2.jpg',
      'productName': 'Green Man T-Shirt',
      'productDescription': 'Blazers',
      'items': [
        {
          'id': 1,
          'price': '$ 19.56',
          'name': 'Orange Football Man',
          'icon': 'checkmark',
        },
        {
          'id': 2,
          'price': '$ 78.60',
          'name': 'Classic Brown Hat',
          'icon': 'checkmark',
        },
        {
          'id': 3,
          'price': '$ 89.16',
          'name': 'Blue Classic Shirt',
          'icon': 'checkmark',
        }
      ],

      'button': 'Add to Cart',
    }
  }


  // DATA FOR E-COMMERCE --- FILTER LIST
  getDataForDesign5 = (): any => {
    return {
      'headerTitle': 'Filter List',
      'items': [
        {
          'id': 1,
          'nameFilter': 'man',
          'filterList': [
            {
              'id': 1,
              'filter': 'Jackets',
              'icon': 'chevron-forward',
            },
            {
              'id': 2,
              'filter': 'Blazers',
              'icon': 'chevron-forward',
            },
            {
              'id': 3,
              'filter': 'Dresses',
              'icon': 'chevron-forward',
            },
            {
              'id': 4,
              'filter': 'Jumpsuits',
              'icon': 'chevron-forward',
            },
            {
              'id': 5,
              'filter': 'Shirts',
              'icon': 'chevron-forward',
            },
          ]
        },
        {
          'id': 2,
          'nameFilter': 'Women',
          'filterList': [
            {
              'id': 1,
              'filter': 'Jeans',
              'icon': 'chevron-forward',
            },
            {
              'id': 2,
              'filter': 'Skirts',
              'icon': 'chevron-forward',
            },
            {
              'id': 3,
              'filter': 'Shoes',
              'icon': 'chevron-forward',
            },
            {
              'id': 4,
              'filter': 'Bags',
              'icon': 'chevron-forward',
            },
            {
              'id': 5,
              'filter': 'Accessories',
              'icon': 'chevron-forward',
            },
          ]
        }
      ]
    }
  }


  // DATA FOR E-COMMERCE --- FILTERS
  getDataForDesign6 = (): any => {
    return {
      'headerTitle': 'Filters',
      'items': [
        {
          'id': 1,
          'nameFilter': 'man',
          'filterList': [
            {
              'id': 1,
              'filter': 'Jackets',
              'icon': 'chevron-forward',
            },
            {
              'id': 2,
              'filter': 'Blazers',
              'icon': 'chevron-forward',
            },
            {
              'id': 3,
              'filter': 'Dresses',
              'icon': 'chevron-forward',
            },
            {
              'id': 4,
              'filter': 'Jumpsuits',
              'icon': 'chevron-forward',
            },
            {
              'id': 5,
              'filter': 'Shirts',
              'icon': 'chevron-forward',
            },
          ]
        }
      ],
      'size': {
        'size': 'Size',
        'number1': '32',
        'number2': '33',
        'number3': '34',
        'number4': '35',
      },
      'range': {
        'name': 'Price Range',
        'min': '100',
        'max': '2000',
        'step': '100',
        'value': {
          'lower': 500,
          'upper': 900
        },
      }
    }
  }

  // DATA FOR E-COMMERCE --- SHOPPING CART
  getDataForDesign7 = (): any => {
    return {
      'headerTitle': 'Shoping Card',
      'button': 'Add to Cart',
      'items': [
        {
          'id': 1,
          'avatar': 'assets/imgs/shop/1.jpg',
          'title': '$ 14.99',
          'subtitle': 'Brown Woman bag',
          'description': 'Jackets',
          'value': 4,
        },
        {
          'id': 2,
          'avatar': 'assets/imgs/shop/2.jpg',
          'title': '$ 54.99',
          'subtitle': 'Green Man T-Shirt',
          'description': 'Blazers',
          'value': 6,
        },
        {
          'id': 3,
          'avatar': 'assets/imgs/shop/3.jpg',
          'title': '$ 18.99',
          'subtitle': 'Orange Football Man',
          'description': 'Dresses',
          'value': 1,
        },
        {
          'id': 4,
          'avatar': 'assets/imgs/shop/4.jpg',
          'title': '$ 14.56',
          'subtitle': 'Classic Brown Hat',
          'description': 'Jumpsuits',
          'value': 3,
        },
        {
          'id': 5,
          'avatar': 'assets/imgs/shop/5.jpg',
          'title': '$ 89.99',
          'subtitle': 'Blue Classic Shirt',
          'description': 'Shirts',
          'value': 3,
        },
        {
          'id': 6,
          'avatar': 'assets/imgs/shop/6.jpg',
          'title': '$ 74.99',
          'subtitle': 'Big Orange Backpack',
          'description': 'Jeans',
          'value': 3,
        },
        {
          'id': 7,
          'avatar': 'assets/imgs/shop/7.jpg',
          'title': '$ 89.18',
          'subtitle': 'Colorfufl Hiphop Hat',
          'description': 'Skirts',
          'value': 3,
        },
        {
          'id': 8,
          'avatar': 'assets/imgs/shop/8.jpg',
          'title': '$ 56.89',
          'subtitle': 'Blue Man Shorts',
          'description': 'Shoes',
          'value': 3,
        }
      ]
    };
  }

  // DATA FOR E-COMMERCE --- PRODUCT BIG IMAGE
  getDataForDesign8 = (): any => {
    return {
      'image': 'assets/imgs/shop/2.jpg',
      'headerTitle': 'Product Big Image',
      'title': 'Blue Sport Leggings',
      'newPrice': '$ 11.99',
      'oldPrice': '$ 14.99',
      'description': 'Blue Classic Shirt',
      'button': 'Add to Cart',
    }
  }

  // DATA FOR E-COMMERCE --- SHOP WITH SLIDER
  getDataForDesign9 = (): any => {
    return {
      'headerTitle': 'Shop with Slider',
      'sliderName': 'Sale Products',
      'items': [
        {
          'id': 1,
          'shopImage': 'assets/imgs/shop/1.jpg',
          'shopTitle': 'Brown Woman bag',
          'shopSubtitle': 'Jackets',
          'price': '$ 32.99',
          'shopDescription': 'Sale Price',
        },
        {
          'id': 2,
          'shopImage': 'assets/imgs/shop/2.jpg',
          'shopTitle': 'Green Man T-Shirt',
          'shopSubtitle': 'Blazers',
          'price': '$ 52.89',
          'shopDescription': 'Sale Price',
        },
        {
          'id': 3,
          'shopImage': 'assets/imgs/shop/3.jpg',
          'shopTitle': 'Orange Football Man',
          'shopSubtitle': 'Dresses',
          'price': '$ 86.09',
          'shopDescription': 'Sale Price',
        }
      ],

      'listName': 'Latest Product',
      'listItem': [
        {
          'id': 1,
          'image': 'assets/imgs/shop/4.jpg',
          'price': '$ 32.99',
          'title': 'Classic Brown Hat',
          'subtitle': 'Jumpsuits',
        },

        {
          'id': 2,
          'image': 'assets/imgs/shop/5.jpg',
          'price': '$ 32.99',
          'title': 'Blue Classic Shirt',
          'subtitle': 'Shirts',
        },

        {
          'id': 3,
          'image': 'assets/imgs/shop/6.jpg',
          'price': '$ 32.99',
          'title': 'Big Orange Backpack',
          'subtitle': 'Jeans',
        },

        {
          'id': 4,
          'image': 'assets/imgs/shop/7.jpg',
          'price': '$ 32.99',
          'title': 'Colorfufl Hiphop Hat',
          'subtitle': 'Skirts',
        }
      ]
    }
  }

  // DATA FOR E-COMMERCE --- SHOP WITH COVER
  getDataForDesign10 = (): any => {
    return {
      'headerTitle': 'Shop with Cover',
      'headerImage': 'assets/imgs/shop/7.jpg',
      'items': [
        {
          'id': 1,
          'shopImage': 'assets/imgs/shop/1.jpg',
          'price': '$ 32.99',
          'shopTitle': 'Brown Woman bag',
          'shopSubtitle': 'Jackets',
          'icon': 'basket',

        },
        {
          'id': 2,
          'shopImage': 'assets/imgs/shop/2.jpg',
          'price': '$ 12.99',
          'shopTitle': 'Green Man T-Shirt',
          'shopSubtitle': 'Blazers',
          'icon': 'basket',
        },
        {
          'id': 3,
          'shopImage': 'assets/imgs/shop/3.jpg',
          'price': '$ 89.99',
          'shopTitle': 'Orange Football Man',
          'shopSubtitle': 'Dresses',
          'icon': 'basket',
        },
        {
          'id': 4,
          'shopImage': 'assets/imgs/shop/4.jpg',
          'price': '$ 45.19',
          'shopTitle': 'Classic Brown Hat',
          'shopSubtitle': 'Jumpsuits',
          'icon': 'basket',
        },
        {
          'id': 5,
          'shopImage': 'assets/imgs/shop/5.jpg',
          'shopTitle': 'Blue Classic Shirt',
          'price': '$ 32.09',
          'shopSubtitle': 'Shirts',
          'icon': 'basket',
        },
        {
          'id': 6,
          'shopImage': 'assets/imgs/shop/6.jpg',
          'shopTitle': 'Big Orange Backpack',
          'price': '$ 59.90',
          'shopSubtitle': 'Jeans',
          'icon': 'basket',
        },
        {
          'id': 7,
          'shopImage': 'assets/imgs/shop/7.jpg',
          'shopTitle': 'Colorfufl Hiphop Hat',
          'price': '$ 45.96',
          'shopSubtitle': 'Skirts',
          'icon': 'basket',
        }
      ]
    }
  }

  // DATA FOR E-COMMERCE --- SHOPPING CART
  getDataForDesign11 = (): any => {
    return {
      'headerTitle': ' Checkout with Total amount',
      'subtotal': 'Subtotal',
      'discount': 'Discount ',
      'total': 'Total',
      'subtotalValue': '$ 2,276.75',
      'discountValue': '20% ',
      'totalValue': '$ 1,821.4',
      'button': 'Proceed to Checkout',
      'items': [
        {
          'id': 1,
          'avatar': 'assets/imgs/shop/1.jpg',
          'price': '$ 14.99',
          'title': 'Brown Woman bag',
          'value': 4,
          'iconRemove': 'trash-outline',
          'remove': 'Remove'
        },
        {
          'id': 2,
          'avatar': 'assets/imgs/shop/2.jpg',
          'price': '$ 54.99',
          'title': 'Green Man T-Shirt',
          'value': 6,
          'iconRemove': 'trash-outline',
          'remove': 'Remove'
        },
        {
          'id': 3,
          'avatar': 'assets/imgs/shop/3.jpg',
          'price': '$ 18.99',
          'title': 'Orange Football Man',
          'value': 1,
          'iconRemove': 'trash-outline',
          'remove': 'Remove'
        }
      ],
    }
  }

  // DATA FOR PAYMENT
  getDataForDesign12 = (): any => {
    return {
      'headerTitle': ' Payment',
      'subtotal': 'Subtotal',
      'discount': 'Discount ',
      'total': 'Total',
      'subtotalValue': '$ 2,276.75',
      'discountValue': '20% ',
      'totalValue': '$ 1,821.4',
      'button': 'Pay Now',
      'slide': [
        {
          'id': 1,
          'title': 'VISA',
          'subtitle': 'Eric Latero',
          'description': '81 Green Lake Avenue Washington, DC 20235',
          'number': '0023 5476 0202 7834',
        },
        {
          'id': 2,
          'title': 'VISA',
          'subtitle': 'Eric Latero',
          'description': '81 Green Lake Avenue Washington, DC 20235',
          'number': '0023 5476 0202 7834',
        }
      ],
      'items': [
        {
          'id': 1,
          'avatar': 'assets/imgs/shop/1.jpg',
          'price': '$ 14.99',
          'title': 'Brown Woman bag',
          'value': 4,
          'iconRemove': 'trash-outline',
          'remove': 'Remove'
        },
        {
          'id': 2,
          'avatar': 'assets/imgs/shop/2.jpg',
          'price': '$ 54.99',
          'title': 'Green Man T-Shirt',
          'value': 6,
          'iconRemove': 'trash-outline',
          'remove': 'Remove'
        },
        {
          'id': 3,
          'avatar': 'assets/imgs/shop/3.jpg',
          'price': '$ 18.99',
          'title': 'Orange Football Man',
          'value': 1,
          'iconRemove': 'trash-outline',
          'remove': 'Remove'
        }
      ],
    }
  }

  // DATA FOR Congratulations
  getDataForDesign13 = (): any => {
    return {
      'headerTitle': ' Congratulations',
      'title': 'Congratulations',
      'subtitle': 'Your payment is complete. ',
      'description': 'Please check the delivery status.',
      'subtotalValue': '$ 2,276.75',
      'icon': 'checkmark',
      'button': 'Continue Shopping',
    }
  }

  load(menuItem: MenuItem): Observable<any> {
    var that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('ecommerce/layout' + menuItem.id)
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getDataByMenuId(menuItem));
        observer.complete();
      });
    }
  }

  loadEvents(menuItem: MenuItem) {
    return this.geEventsByMenuId(menuItem);
  }

  getDataByMenuId(menuItem: MenuItem) {
    switch (menuItem.id) {
      case 1:
        return this.getDataForDesign1();

      case 2:
        return this.getDataForDesign2();

      case 3:
        return this.getDataForDesign3();

      case 4:
        return this.getDataForDesign4();

      case 5:
        return this.getDataForDesign5();

      case 6:
        return this.getDataForDesign6();

      case 7:
        return this.getDataForDesign7();

      case 8:
        return this.getDataForDesign8();

      case 9:
        return this.getDataForDesign9();

      case 10:
        return this.getDataForDesign10();

      case 11:
        return this.getDataForDesign11();

      case 12:
        return this.getDataForDesign12();

      case 13:
        return this.getDataForDesign13();
    }

  }

  geEventsByMenuId(menuItem: MenuItem): any {
    switch (menuItem.id) {
      case 1:
        return this.getEventsForDesign1();

      case 2:
        return this.getEventsForDesign2();

      case 3:
        return this.getEventsForDesign3();

      case 4:
        return this.getEventsForDesign4();

      case 5:
        return this.getEventsForDesign5();

      case 6:
        return this.getEventsForDesign6();

      case 7:
        return this.getEventsForDesign7();

      case 8:
        return this.getEventsForDesign8();

      case 9:
        return this.getEventsForDesign9();

      case 10:
        return this.getEventsForDesign10();

      case 11:
        return this.getEventsForDesign11();

      case 12:
        return this.getEventsForDesign12();

      case 13:
        return this.getEventsForDesign13();
    }
  }

  getEventsForDesign1(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      }
    };
  }

  getEventsForDesign2(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.shopTitle);
      },
      'onClickButton': function (item: any) {
        that.toastCtrl.presentToast('Add to Cart');
      }
    };
  }

  getEventsForDesign3(): any {
    var that = this;
    return {
      'onClickButton': function (item: any) {
        that.toastCtrl.presentToast('Add to Cart');
      }
    };
  }

  getEventsForDesign4(): any {
    var that = this;
    return {
      'onClickButton': function (item: any) {
        that.toastCtrl.presentToast('Add to Cart');
      }
    };
  }

  getEventsForDesign5(): any {
    var that = this;
    return {
      'onItemClick': function (list) {
        that.toastCtrl.presentToast(list.filter);
      }
    };
  }


  getEventsForDesign6(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.filter);
      }
    };
  }

  getEventsForDesign7(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast('Add to Cart');
      },
      'onItemIncrement': function (item: any) {
        item.value++;
      },
      'onItemDecrement': function (item: any) {
        if (item.value > 0) {
          item.value--;
        }
      }
    };
  }

  getEventsForDesign8(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast('Add to Cart');
      }
    };
  }

  getEventsForDesign9(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      },
      'onItemClickSlider': function (item: any) {
        that.toastCtrl.presentToast(item.shopSubtitle);
      }
    };
  }

  getEventsForDesign10(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.shopSubtitle);
      }
    };
  }

  getEventsForDesign11(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.shopSubtitle);
      }
    };
  }

  getEventsForDesign12(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.shopSubtitle);
      }
    };
  }

  getEventsForDesign13(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.shopSubtitle);
      }
    };
  }
}
