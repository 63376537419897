import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class HomeService {

  constructor(private loadingService: LoadingService, public af: AngularFireDatabase) { }

  load(): Observable<any> {
    var that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('home')
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getHomeData());
        observer.complete();
      });
    }
  }

  getHomeData() {
    return {
      'hederTitle': 'Home',
      'headerImage': 'assets/imgs/content/12.png',
      'title': 'Mobile user inteface kit for desingers & devs.',
      'contentImage': 'assets/imgs/content/11.png',
      'btnDiscover': 'Discover Devspush',
      'btnPurchase': 'Purchase Devspush'
    }
  }
}
