import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: '/corepage/mainpage', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'items/:type',
    loadChildren: () => import('./pages/navigation/navigation.module').then(m => m.NavigationPageModule)
  },
  {
    path: 'e-commerce/:type',
    loadChildren: () => import('./pages/e-commerce/e-commerce.module').then(m => m.ECommercePageModule)
  },
  {
    path: 'media/:type',
    loadChildren: () => import('./pages/media/media.module').then(m => m.MediaPageModule)
  },
  {
    path: 'profile/:type',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'signup/:type',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'social/:type',
    loadChildren: () => import('./pages/social/social.module').then(m => m.SocialPageModule)
  },
  {
    path: 'walkthrought/:type',
    loadChildren: () => import('./pages/walkthrought/walkthrought.module').then(m => m.WalkthroughtPageModule)
  },
  {
    path: 'e-commerce/:type',
    loadChildren: () => import('./pages/e-commerce/e-commerce.module').then(m => m.ECommercePageModule)
  },
  {
    path: 'check box/:type',
    loadChildren: () => import('./pages/check-box/check-box.module').then(m => m.CheckBoxPageModule)
  },
  {
    path: 'toggle button/:type',
    loadChildren: () => import('./pages/toggle-button/toggle-button.module').then(m => m.ToggleButtonPageModule)
  },
  {
    path: 'radio button/:type',
    loadChildren: () => import('./pages/radio-button/radio-button.module').then(m => m.RadioButtonPageModule),
  },
  {
    path: 'lists/:type',
    loadChildren: () => import('./pages/lists/lists.module').then(m => m.ListsPageModule)
  },
  {
    path: 'other/:type',
    loadChildren: () => import('./pages/other/other.module').then(m => m.OtherPageModule)
  },
  {
    path: 'range/:type',
    loadChildren: () => import('./pages/range/range.module').then(m => m.RangePageModule)
  },
  {
    path: 'tab/:type',
    loadChildren: () => import('./pages/tab/tab.module').then(m => m.TabPageModule)
  },
  {
    path: 'landingpage',
    loadChildren: () => import('./pages/landingpage/landingpage.module').then( m => m.LandingpagePageModule)
  },
  {
    path: 'mainpage',
    loadChildren: () => import('./pages/mainpage/mainpage.module').then( m => m.MainpagePageModule)
  },
  {
    path: 'postlist',
    loadChildren: () => import('./pages/postlist/postlist.module').then( m => m.PostlistPageModule)
  },
  {
    path: 'corepage',
    loadChildren: () => import('./pages/corepage/corepage.module').then( m => m.CorepagePageModule)
  },
  {
    path: 'favourites',
    loadChildren: () => import('./pages/favourites/favourites.module').then( m => m.FavouritesPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'feedpage',
    loadChildren: () => import('./pages/feedpage/feedpage.module').then( m => m.FeedpagePageModule)
  },
  {
    path: 'postingpage',
    loadChildren: () => import('./pages/postingpage/postingpage.module').then( m => m.PostingpagePageModule)
  },
  {
    path: 'signuppage',
    loadChildren: () => import('./pages/signuppage/signuppage.module').then( m => m.SignuppagePageModule)
  },
  {
    path: 'loginpage',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'paymentpage',
    loadChildren: () => import('./pages/paymentpage/paymentpage.module').then( m => m.PaymentpagePageModule)
  },
  {
    path: 'postingdetailpage',
    loadChildren: () => import('./pages/postingdetailpage/postingdetailpage.module').then( m => m.PostingdetailpagePageModule)
  },
  {
    path: 'profilepage',
    loadChildren: () => import('./pages/profilepage/profilepage.module').then( m => m.ProfilepagePageModule)
  },
  {
    path: 'voucheradsconfig',
    loadChildren: () => import('./pages/voucheradsconfig/voucheradsconfig.module').then( m => m.VoucheradsconfigPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },
  {
    path: 'changepasssword',
    loadChildren: () => import('./pages/changepasssword/changepasssword.module').then( m => m.ChangepassswordPageModule)
  }




 




  // {
  //   path: 'login',
  //   loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  // }








];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
