import { Constants } from './constants';

export class MenuItem {
    id: number;
    counter: number
    name: string;
    type: string;
    data: any;
    events: any;

    constructor(id: number, title: string, counter: number, type: string) {
        this.id = id;
        this.name = title;
        this.counter = counter;
        this.type = type;
    }

    getUrl() {
        if (this.type == Constants.TAB) {
            return `tab/${this.id - 1}/tab/tab${1 + (this.id - 1) * 3}`
        }
        return `${this.type.toLowerCase()}/${this.id - 1}`;
    }
}