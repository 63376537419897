import { Injectable } from '@angular/core';
import { IService } from './IService';
import { MenuItem } from './MenuItem';
import { Constants } from './constants';
import { ToastService } from './toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class SocialService implements IService {

  constructor(private toastCtrl: ToastService, private loadingService: LoadingService, public af: AngularFireDatabase) { }

  getMenuList(): MenuItem[] {
    var menuItemList = [];
    menuItemList.push(new MenuItem(1, 'Feed - Full Width', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(2, 'Author Card', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(3, 'Image Cards', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(4, 'Cards', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(5, 'Big Images', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(6, 'Comments', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(7, 'Activity Cards', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(8, 'Article - Cover Image', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(9, 'Write Comment', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(10, 'Image with title', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(11, 'Feed / Events', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(12, 'Feed / Events', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(13, 'Feed / Article', 0, Constants.SOCIAL));
    menuItemList.push(new MenuItem(14, 'Feed / Big Image with Title', 0, Constants.SOCIAL));
    return menuItemList;
  }

  // DATA FOR SOCIAL--- FEED - FULL WIDTH
  getDataForDesign1 = (): any => {
    return {
      'headerTitle': 'Feed - Full Width',
      'items': [
        {
          'id': 1,
          'avatar': 'assets/imgs/avatar/1.jpg',
          'avatarTitle': 'John Perkins',
          'dataTime': 'March 14th, 2019',
          'image': 'assets/imgs/content/1.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '250',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '569',
          'title': 'Pink Classic Chair',
          'description': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.'
        },
        {
          'id': 2,
          'avatar': 'assets/imgs/avatar/2.jpg',
          'avatarTitle': 'Dennis Wells',
          'dataTime': 'March 19th, 2019',
          'image': 'assets/imgs/content/2.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '156',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '25',
          'title': 'Deep Sea Jellyfish',
          'description': 'Jellyfish or sea jelly is the informal common name given to the medusa-phase of certain gelatinous'
        },
        {
          'id': 3,
          'avatar': 'assets/imgs/avatar/3.jpg',
          'avatarTitle': 'Samantha Bates',
          'dataTime': 'April 27th, 2019',
          'image': 'assets/imgs/content/3.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '56',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '32',
          'title': 'Floating Pink Cherry',
          'description': 'A cherry is the fruit of many plants of the genus Prunus, and is a fleshy drupe'
        },
        {
          'id': 4,
          'avatar': 'assets/imgs/avatar/4.jpg',
          'avatarTitle': 'Lawrence Murray',
          'dataTime': 'June 15th, 2019',
          'image': 'assets/imgs/content/4.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '98',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '1',
          'title': 'Green Leaf Pattern',
          'description': 'A green leaf pattern is a discernible regularity in the world or in a manmade design.'
        },
        {
          'id': 5,
          'avatar': 'assets/imgs/avatar/5.jpg',
          'avatarTitle': 'Teresa Olsen',
          'dataTime': 'August 25th, 2019',
          'image': 'assets/imgs/content/5.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '45',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '96',
          'title': 'Trees and Blue River',
          'description': 'Along the Blue River can be found many miles of hiking, biking, and walking trails.'
        },
        {
          'id': 6,
          'avatar': 'assets/imgs/avatar/6.jpg',
          'avatarTitle': 'Barbara Morales',
          'dataTime': 'April 22th, 2019',
          'image': 'assets/imgs/content/6.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '40',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '6',
          'title': 'Pink Jellyfish in Sea',
          'description': 'Jellyfish are eaten by humans in certain cultures, being considered a delicacy in some countries.'
        },
        {
          'id': 7,
          'avatar': 'assets/imgs/avatar/7.jpg',
          'avatarTitle': 'Victoria Gilbert',
          'dataTime': 'July 14th, 2019',
          'image': 'assets/imgs/content/7.jpg',
          'iconHeart': 'heart',
          'iconHeartValues': '40',
          'isFavorite': false,
          'iconComments': 'chatbubble',
          'iconCommentsValues': '6',
          'title': 'Orange Stairs Building',
          'description': 'The vertical portion between each tread on the stair. This may be missing for an \'open\' stair effect.',
        }
      ]
    };
  }

  // DATA FOR SOCIAL--- ARTICLE - AUTHOR CARDS
  getDataForDesign2 = (): any => {
    return {
      'headerTitle': 'Article - Author Card',
      'avatar': 'assets/imgs/avatar/1.jpg',
      'avatarTitle': 'John Perkins',
      'jobPosition': 'Graphic Designer',
      'image': 'assets/imgs/content/15.jpg',
      'title': 'Pink Classic Chair',
      'time': 'MARCH 14th, 2018',
      'views': '1459 Views',
      'button': 'Comments (32)',
      'description': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.',

      'listComments': [
        {
          'id': 1,
          'time': 'March 14th, 2019',
          'comment': 'This is your last chance. After this, there is no turning back. You take the blue pill - the story…',
          'avatar': 'assets/imgs/avatar/2.jpg',
          'avatarTitle': 'Dennis Wells',

        },
        {
          'id': 2,
          'time': 'March 19th, 2019',
          'comment': 'This is your last chance. After this, there is no turning back. You take the blue pill - the story…',
          'avatar': 'assets/imgs/avatar/3.jpg',
          'avatarTitle': 'Samantha Bates',

        },
        {
          'id': 3,
          'time': 'April 27th, 2019',
          'comment': 'This is your last chance. After this, there is no turning back. You take the blue pill - the story…',
          'avatar': 'assets/imgs/avatar/4.jpg',
          'avatarTitle': 'Lawrence Murray',

        }
      ]
    }
  }

  // DATA FOR SOCIAL--- IMAGES CARDS
  getDataForDesign3 = (): any => {
    return {
      'headerTitle': ' Image Cards',
      'items': [
        {
          'id': 1,
          'image': 'assets/imgs/content/1.jpg',
          'title': 'Pink Classic Chair',
          'description': 'Dusty pink chair',
          'icon': 'heart',
          'iconValue': '210'
        },
        {
          'id': 2,
          'image': 'assets/imgs/content/2.jpg',
          'title': 'Deep Sea Jellyfish',
          'description': 'Jelly fish or sea jelly',
          'icon': 'heart',
          'iconValue': '199'
        },
        {
          'id': 3,
          'image': 'assets/imgs/content/3.jpg',
          'title': 'Floating Pink Cherry',
          'description': 'Cherry is the fruit',
          'icon': 'heart',
          'iconValue': '189'
        },
        {
          'id': 4,
          'image': 'assets/imgs/content/4.jpg',
          'title': 'Green Leaf Pattern',
          'description': 'World or manmade design',
          'icon': 'heart',
          'iconValue': '120'
        },
        {
          'id': 5,
          'image': 'assets/imgs/content/5.jpg',
          'title': 'Trees and Blue River',
          'description': 'Hiking, biking and walking',
          'icon': 'heart',
          'iconValue': '123'
        },
        {
          'id': 6,
          'image': 'assets/imgs/content/6.jpg',
          'title': 'Pink Jellyfish in Sea',
          'description': 'Eaten by humans',
          'icon': 'heart',
          'iconValue': '89'
        },
        {
          'id': 7,
          'image': 'assets/imgs/content/7.jpg',
          'title': 'Orange Stairs Building',
          'description': 'Open stair effect',
          'icon': 'heart',
          'iconValue': '70'
        },
        {
          'id': 8,
          'image': 'assets/imgs/content/8.jpg',
          'title': 'Color full Tennis Court',
          'description': 'Rocket sport',
          'icon': 'heart',
          'iconValue': '56'
        }
      ]
    }
  }

  // DATA FOR SOCIAL--- CARDS
  getDataForDesign4 = (): any => {
    return {
      'headerTitle': ' Image Cards',
      'items': [
        {
          'id': 1,
          'images': 'assets/imgs/content/1.jpg',
          'title': 'Pink Classic Chair',
          'description': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.',
          'dataTime': 'March 14th, 2019',
        },
        {
          'id': 2,
          'images': 'assets/imgs/content/2.jpg',
          'title': 'Deep Sea Jellyfish',
          'description': 'Jellyfish or sea jelly is the informal common name given to the medusa-phase of certain gelatinous.',
          'dataTime': 'March 19th, 2019',
        },
        {
          'id': 3,
          'images': 'assets/imgs/content/3.jpg',
          'title': 'Floating Pink Cherry',
          'description': 'A cherry is the fruit of many plants of the genus Prunus, and is a fleshy drupe.',
          'dataTime': 'April 27th, 2019',
        },
        {
          'id': 4,
          'images': 'assets/imgs/content/4.jpg',
          'title': 'Green Leaf Pattern',
          'description': 'A green leaf pattern is a discernible regularity in the world or in a manmade design.',
          'dataTime': 'June 15th, 2019',
        },
        {
          'id': 5,
          'images': 'assets/imgs/content/5.jpg',
          'title': 'Trees and Blue River',
          'description': 'Along the Blue River can be found many miles of hiking, biking, and walking trails.',
          'dataTime': 'August 25th, 2019',
        },
        {
          'id': 6,
          'images': 'assets/imgs/content/6.jpg',
          'title': 'Pink Jellyfish in Sea',
          'description': 'Jellyfish are eaten by humans in certain cultures, being considered a delicacy in some countries.',
          'dataTime': 'April 22th, 2019',
        },
        {
          'id': 7,
          'images': 'assets/imgs/content/7.jpg',
          'title': 'Orange Stairs Building',
          'description': 'The vertical portion between each tread on the stair. This may be missing for an \'open\' stair effect.',
          'dataTime': 'July 14th, 2019',
        },
        {
          'id': 8,
          'images': 'assets/imgs/content/8.jpg',
          'title': 'Color full Tennis Court',
          'description': 'Tennis is a racket sport that can be played individually against a single opponent or between two teams',
          'dataTime': 'January 29th, 2019',
        },
        {
          'id': 9,
          'images': 'assets/imgs/content/9.jpg',
          'title': 'Green Vegetables Pattern',
          'description': 'Vegetables are parts of plants that are consumed by humans as food as part of a meal.',
          'dataTime': 'April 17th, 2019',
        },
        {
          'id': 10,
          'images': 'assets/imgs/content/10.jpg',
          'title': 'Abstract Blue Wallpaper',
          'description': 'An abstract is a brief summary of a research article, thesis, review, conference proceeding.',
          'dataTime': 'May 12th, 2019',
        }
      ]
    }
  }

  // DATA FOR SOCIAL--- BIG IMAGES
  getDataForDesign5 = (): any => {
    return {
      'headerTitle': 'Feed - Big Images',
      'bigTitle': 'Discover New & Amazing Stuff.',
      'items': [
        {
          'id': 1,
          'images': 'assets/imgs/content/1.jpg',
          'title': 'Pink Classic Chair',
          'dataTime': 'March 14th, 2019',
          'feedList': [
            {
              'id': 1,
              'feedImages': 'assets/imgs/content/2.jpg',
              'feedTitle': 'Deep Sea Jellyfish',
              'feedDataTime': 'March 19th, 2019',
            },

            {
              'id': 2,
              'feedImages': 'assets/imgs/content/3.jpg',
              'feedTitle': 'Floating Pink Cherry',
              'feedDataTime': 'April 27th, 2019',
            },

            {
              'id': 3,
              'feedImages': 'assets/imgs/content/4.jpg',
              'feedTitle': 'Green Leaf Pattern',
              'feedDataTime': 'June 15th, 2019',
            },
            {
              'id': 4,
              'feedImages': 'assets/imgs/content/7.jpg',
              'feedTitle': 'Orange Stairs Building',
              'feedDataTime': 'July 14th, 2019',
            }
          ],
        },

        {
          'id': 2,
          'images': 'assets/imgs/content/5.jpg',
          'title': 'Trees and Blue River',
          'dataTime': 'August 25th, 2019',
          'feedList': [
            {
              'id': 1,
              'feedImages': 'assets/imgs/content/6.jpg',
              'feedTitle': 'Pink Jellyfish in Sea',
              'feedDataTime': 'April 22th, 2019',
            },

            {
              'id': 2,
              'feedImages': 'assets/imgs/content/7.jpg',
              'feedTitle': 'Orange Stairs Building',
              'feedDataTime': 'July 14th, 2019',
            },

            {
              'id': 3,
              'feedImages': 'assets/imgs/content/8.jpg',
              'feedTitle': 'Color full Tennis Court',
              'feedDataTime': 'January 29th, 2019',
            },
            {
              'id': 4,
              'feedImages': 'assets/imgs/content/4.jpg',
              'feedTitle': 'Green Leaf Pattern',
              'feedDataTime': 'June 15th, 2019',
            }
          ],
        },

        {
          'id': 3,
          'images': 'assets/imgs/content/9.jpg',
          'title': 'Green Vegetables Pattern',
          'dataTime': 'April 17th, 2019',
          'feedList': [
            {
              'id': 1,
              'feedImages': 'assets/imgs/content/10.jpg',
              'feedTitle': 'Abstract Blue',
              'feedDataTime': 'May 12th, 2019'
            },

            {
              'id': 2,
              'feedImages': 'assets/imgs/content/1.jpg',
              'feedTitle': 'Pink Classic Chair',
              'feedDataTime': 'March 14th, 2019',
            },

            {
              'id': 3,
              'feedImages': 'assets/imgs/content/2.jpg',
              'feedTitle': 'Deep Sea Jellyfish',
              'feedDataTime': 'March 19th, 2019',
            },
            {
              'id': 4,
              'feedImages': 'assets/imgs/content/6.jpg',
              'feedTitle': 'Pink Jellyfish in Sea',
              'feedDataTime': 'April 22th, 2019',
            },
          ],
        },

        {
          'id': 4,
          'images': 'assets/imgs/content/3.jpg',
          'title': 'Floating Pink Cherry',
          'dataTime': 'April 27th, 2019',
          'feedList': [
            {
              'id': 1,
              'feedImages': 'assets/imgs/content/4.jpg',
              'feedTitle': 'Green Leaf Pattern',
              'feedDataTime': 'June 15th, 2019',
            },

            {
              'id': 2,
              'feedImages': 'assets/imgs/content/5.jpg',
              'feedTitle': 'Trees and Blue River',
              'feedDataTime': 'August 25th, 2019',
            },

            {
              'id': 3,
              'feedImages': 'assets/imgs/content/6.jpg',
              'feedTitle': 'Pink Jellyfish in Sea',
              'feedDataTime': 'April 22th, 2019',
            },
            {
              'id': 4,
              'feedImages': 'assets/imgs/content/6.jpg',
              'feedTitle': 'Pink Jellyfish in Sea',
              'feedDataTime': 'April 22th, 2019',
            },
          ],
        }
      ]
    }
  }

  // DATA FOR SOCIAL--- COMMENTS
  getDataForDesign6 = (): any => {
    return {
      'headerTitle': 'Comments',
      'button': 'Write a Comment',
      'items': [
        {
          'id': 1,
          'time': 'March 14th, 2019',
          'comment': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.',
          'avatar': 'assets/imgs/avatar/1.jpg',
          'avatarTitle': 'John Perkins',
        },
        {
          'id': 2,
          'time': 'March 19th, 2019',
          'comment': 'Jellyfish or sea jelly is the informal common name given to the medusa-phase of certain gelatinous.',
          'avatar': 'assets/imgs/avatar/2.jpg',
          'avatarTitle': 'Dennis Wells',
        },
        {
          'id': 3,
          'time': 'April 27th, 2019',
          'comment': 'A cherry is the fruit of many plants of the genus Prunus, and is a fleshy drupe.',
          'avatar': 'assets/imgs/avatar/3.jpg',
          'avatarTitle': 'Samantha Bates',
        },
        {
          'id': 4,
          'time': 'June 15th, 2019',
          'comment': 'A green leaf pattern is a discernible regularity in the world or in a manmade design.',
          'avatar': 'assets/imgs/avatar/4.jpg',
          'avatarTitle': 'Lawrence Murray',
        },
        {
          'id': 5,
          'time': 'August 25th, 2019',
          'comment': 'Along the Blue River can be found many miles of hiking, biking, and walking trails.',
          'avatar': 'assets/imgs/avatar/5.jpg',
          'avatarTitle': 'Teresa Olsen',
        },
        {
          'id': 6,
          'time': 'April 22th, 2019',
          'comment': 'Jellyfish are eaten by humans in certain cultures, being considered a delicacy in some countries.',
          'avatar': 'assets/imgs/avatar/6.jpg',
          'avatarTitle': 'Barbara Morales',
        },
        {
          'id': 7,
          'time': 'July 14th, 2019',
          'comment': 'The vertical portion between each tread on the stair. This may be missing for an \'open\' stair effect.',
          'avatar': 'assets/imgs/avatar/7.jpg',
          'avatarTitle': 'Victoria Gilbert',
        },
        {
          'id': 8,
          'time': 'January 29th, 2019',
          'comment': 'Tennis is a racket sport that can be played individually against a single opponent or between two teams',
          'avatar': 'assets/imgs/avatar/8.jpg',
          'avatarTitle': 'Jack Hansen',
        },
        {
          'id': 9,
          'time': 'April 17th, 2019',
          'comment': 'Vegetables are parts of plants that are consumed by humans as food as part of a meal.',
          'avatar': 'assets/imgs/avatar/9.jpg',
          'avatarTitle': 'Michael Bell',
        },
        {
          'id': 10,
          'time': 'May 12th, 2019',
          'comment': 'An abstract is a brief summary of a research article, thesis, review, conference proceeding.',
          'avatar': 'assets/imgs/avatar/10.jpg',
          'avatarTitle': 'Jesse Handsen',
        },
      ]
    }
  }

  // DATA FOR SOCIAL--- ACTIVITY SARDS
  getDataForDesign7 = (): any => {
    return {
      'headerTitle': 'Activity Cards',
      'items': [
        {
          'id': 1,
          'avatar': 'assets/imgs/avatar/1.jpg',
          'avatarTitle': 'John Perkins',
          'comment': 'commented on your photo',
          'commentTime': '4min',
          'images': 'assets/imgs/content/1.jpg',
        },
        {
          'id': 2,
          'avatar': 'assets/imgs/avatar/2.jpg',
          'avatarTitle': 'Dennis Wells',
          'comment': 'commented on your photo',
          'commentTime': '6min',
          'images': 'assets/imgs/content/2.jpg',
        },
        {
          'id': 3,
          'avatar': 'assets/imgs/avatar/3.jpg',
          'avatarTitle': 'Samantha Bates',
          'comment': 'commented on your photo',
          'commentTime': '9min',
          'images': 'assets/imgs/content/3.jpg',
        },
        {
          'id': 4,
          'avatar': 'assets/imgs/avatar/4.jpg',
          'avatarTitle': 'Lawrence Murray',
          'comment': 'commented on your photo',
          'commentTime': '10min',
          'images': 'assets/imgs/content/4.jpg',
        },
        {
          'id': 5,
          'avatar': 'assets/imgs/avatar/5.jpg',
          'avatarTitle': 'Teresa Olsen',
          'comment': 'commented on your photo',
          'commentTime': '14min',
          'images': 'assets/imgs/content/5.jpg',
        },
        {
          'id': 6,
          'avatar': 'assets/imgs/avatar/6.jpg',
          'avatarTitle': 'Barbara Morales',
          'comment': 'commented on your photo',
          'commentTime': '19min',
          'images': 'assets/imgs/content/6.jpg',
        },
        {
          'id': 7,
          'avatar': 'assets/imgs/avatar/7.jpg',
          'avatarTitle': 'Victoria Gilbert',
          'comment': 'commented on your photo',
          'commentTime': '25min',
          'images': 'assets/imgs/content/7.jpg',
        },
        {
          'id': 8,
          'avatar': 'assets/imgs/avatar/8.jpg',
          'avatarTitle': 'Jack Hansen',
          'comment': 'commented on your photo',
          'commentTime': '37min',
          'images': 'assets/imgs/content/8.jpg',
        },
        {
          'id': 9,
          'avatar': 'assets/imgs/avatar/9.jpg',
          'avatarTitle': 'Michael Bell',
          'comment': 'commented on your photo',
          'commentTime': '41min',
          'images': 'assets/imgs/content/9.jpg',
        },
        {
          'id': 10,
          'avatar': 'assets/imgs/avatar/10.jpg',
          'avatarTitle': 'Jesse Handsen',
          'comment': 'commented on your photo',
          'commentTime': '54min',
          'images': 'assets/imgs/content/10.jpg',
        }
      ]
    }
  }


  // DATA FOR SOCIAL--- ARTICLE - COVER IMAGE
  getDataForDesign8 = (): any => {
    return {
      'headerTitle': 'Article - Cover Image',
      'images': 'assets/imgs/content/16.jpg',
      'title': 'Pink Classic Chair',
      'dataTime': 'March 14th, 2019',
      'views': '1459 Views',
      'contentDescription1': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.',
      'contentImages1': 'assets/imgs/content/2.jpg',
      'contentImages2': 'assets/imgs/content/3.jpg',
      'contentDescription2': 'Jellyfish or sea jelly is the informal common name given to the medusa-phase of certain gelatinous.',
      'contentDescription3': 'A cherry is the fruit of many plants of the genus Prunus, and is a fleshy drupe.',
      'button': 'Comments (32)',
    }
  }


  // DATA FOR SOCIAL--- WRITE COMMENT
  getDataForDesign9 = (): any => {
    return {
      'headerTitle': 'Write Comments',
      'placeholder': 'Write your comment here…',
      'button': 'Submit',
    }
  }


  // DATA FOR SOCIAL--- IMAGE WITH TITLE
  getDataForDesign10 = (): any => {
    return {
      'headerTitle': 'Image with title',
      'backgroundImage': 'assets/imgs/content/6.jpg',
      'title': 'Pink Classic Chair',
      'dataTime': 'March 14th, 2019',
      'views': '1495 Views',
      'description': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.'
    }
  }

  // DATA FOR SOCIAL--- FEED EVENTS
  getDataForDesign11 = (): any => {
    return {
      'headerTitle': ' Feed / Events',
      'items': [
        {
          'id': 1,
          'image': 'assets/imgs/content/1.jpg',
          'title': 'Pink Classic Chair',
          'avatar': 'assets/imgs/avatar/1.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 2,
          'image': 'assets/imgs/content/2.jpg',
          'title': 'Deep Sea Jellyfish',
          'avatar': 'assets/imgs/avatar/2.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 3,
          'image': 'assets/imgs/content/3.jpg',
          'title': 'Floating Pink Cherry',
          'avatar': 'assets/imgs/avatar/3.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 4,
          'image': 'assets/imgs/content/4.jpg',
          'title': 'Green Leaf Pattern',
          'avatar': 'assets/imgs/avatar/4.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 5,
          'image': 'assets/imgs/content/5.jpg',
          'title': 'Trees and Blue River',
          'avatar': 'assets/imgs/avatar/1.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 6,
          'image': 'assets/imgs/content/6.jpg',
          'title': 'Pink Jellyfish in Sea',
          'avatar': 'assets/imgs/avatar/2.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 7,
          'image': 'assets/imgs/content/7.jpg',
          'title': 'Orange Stairs Building',
          'avatar': 'assets/imgs/avatar/3.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        },
        {
          'id': 8,
          'image': 'assets/imgs/content/8.jpg',
          'title': 'Color full Tennis Court',
          'avatar': 'assets/imgs/avatar/4.jpg',
          'time': '30 mins ago',
          'iconHeart': 'heart',
          'iconComments': 'chatbubble',
          'numberLikes': '169 Likes',
          'numberComments': '69 Comments'
        }
      ]
    }
  }

  // DATA FOR SOCIAL--- CARDS
  getDataForDesign12 = (): any => {
    return {
      'headerTitle': ' Feed / Recommended',
      'title': 'New Stuffs',
      'recommended': 'Recommended',
      'slider': [
        {
          'id': 1,
          'images': 'assets/imgs/content/2.jpg',
          'title': 'Music',
        },
        {
          'id': 2,
          'images': 'assets/imgs/content/3.jpg',
          'title': 'Summer Fruits',
        },
        {
          'id': 3,
          'images': 'assets/imgs/content/4.jpg',
          'title': 'Ice Cream',
        }
      ],
      'items': [
        {
          'id': 1,
          'images': 'assets/imgs/content/1.jpg',
          'description': 'Shop Wayfair for the best dusty pink chair. Enjoy Free Shipping on most stuff, even big stuff.',
          'dataTime': '45m ago',
        },
        {
          'id': 2,
          'images': 'assets/imgs/content/2.jpg',
          'description': 'Jellyfish or sea jelly is the informal common name given to the medusa-phase of certain gelatinous.',
          'dataTime': '47m ago',
        },
        {
          'id': 3,
          'images': 'assets/imgs/content/3.jpg',
          'description': 'A cherry is the fruit of many plants of the genus Prunus, and is a fleshy drupe.',
          'dataTime': '1h ago',
        },
        {
          'id': 4,
          'images': 'assets/imgs/content/4.jpg',
          'description': 'A green leaf pattern is a discernible regularity in the world or in a manmade design.',
          'dataTime': '1h 5m ago',
        },
        {
          'id': 5,
          'images': 'assets/imgs/content/5.jpg',
          'description': 'Along the Blue River can be found many miles of hiking, biking, and walking trails.',
          'dataTime': '1h 10m ago',
        },
        {
          'id': 6,
          'images': 'assets/imgs/content/6.jpg',
          'description': 'Jellyfish are eaten by humans in certain cultures, being considered a delicacy in some countries.',
          'dataTime': '1h 35m ago',
        },
        {
          'id': 7,
          'images': 'assets/imgs/content/7.jpg',
          'description': 'The vertical portion between each tread on the stair. This may be missing for an \'open\' stair effect.',
          'dataTime': '2h ago',
        },
        {
          'id': 8,
          'images': 'assets/imgs/content/8.jpg',
          'description': 'Tennis is a racket sport that can be played individually against a single opponent or between two teams',
          'dataTime': '3h ago',
        }
      ]
    }
  }

  // DATA FOR SOCIAL--- IMAGE WITH TITLE
  getDataForDesign13 = (): any => {
    return {
      'headerTitle': 'Article',
      'backgroundImage': 'assets/imgs/content/6.jpg',
      'image1': 'assets/imgs/content/4.jpg',
      'image2': 'assets/imgs/content/5.jpg',
      'title': 'Life is like an ice cream, enjoy it before it melts.',
      'dataTime': 'March 14th, 2019',
      'category': 'Culture',
      'subtitle': 'You can’t buy happiness, but you can buy ice cream and that is pretty much the same thing.',
      'description1': 'You can’t buy happiness, but you can buy ice cream and that is pretty much the same thing.No, I was just good at holding ice cream cones.I scream. You scream. We all scream for ice cream.Life is like an ice cream, enjoy it before it melts.Never ask a woman who is eating ice cream straight from the carton how she’s doing.One of the very nicest things about life is the way wemust regularly stop whatever it is we are doing anddevote our attention to eating.The cold never bothered me anyway.',
      'description2': 'You can’t buy happiness, but you can buy ice cream and that is pretty much the same thing.No, I was just good at holding ice cream cones.I scream. You scream. We all scream for ice cream.Life is like an ice cream, enjoy it before it melts.Never ask a woman who is eating ice cream straight from the carton how she’s doing.One of the very nicest things about life is the way wemust regularly stop whatever it is we are doing anddevote our attention to eating.The cold never bothered me anyway.',
      'iconHeart': 'heart',
      'iconComments': 'chatbubble',
      'numberLikes': '169 Likes',
      'numberComments': '69 Comments',
      'button': 'Comment'
    }
  }

  // DATA FOR SOCIAL--- IMAGE WITH TITLE
  getDataForDesign14 = (): any => {
    return {
      'headerTitle': 'Big Image with Title',
      'backgroundImage': 'assets/imgs/content/3.jpg',
      'title': 'Life is like an ice cream, enjoy it before it melts.',
      'dataTime': 'March 14th, 2019',
      'category': 'Culture',
    }
  }

  load(menuItem: MenuItem): Observable<any> {
    var that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('social/layout' + menuItem.id)
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getDataByMenuId(menuItem));
        observer.complete();
      });
    }
  }

  loadEvents(menuItem: MenuItem) {
    return this.geEventsByMenuId(menuItem);
  }

  getDataByMenuId(menuItem: MenuItem) {
    switch (menuItem.id) {
      case 1:
        return this.getDataForDesign1();

      case 2:
        return this.getDataForDesign2();

      case 3:
        return this.getDataForDesign3();

      case 4:
        return this.getDataForDesign4();

      case 5:
        return this.getDataForDesign5();

      case 6:
        return this.getDataForDesign6();

      case 7:
        return this.getDataForDesign7();

      case 8:
        return this.getDataForDesign8();

      case 9:
        return this.getDataForDesign9();

      case 10:
        return this.getDataForDesign10();

      case 11:
        return this.getDataForDesign11();

      case 12:
        return this.getDataForDesign12();

      case 13:
        return this.getDataForDesign13();

      case 14:
        return this.getDataForDesign14();
    }

  }

  geEventsByMenuId(menuItem: MenuItem) {
    switch (menuItem.id) {
      case 1:
        return this.getEventsForDesign1();

      case 2:
        return this.getEventsForDesign2();

      case 3:
        return this.getEventsForDesign3();

      case 4:
        return this.getEventsForDesign4();

      case 5:
        return this.getEventsForDesign5();

      case 6:
        return this.getEventsForDesign6();

      case 7:
        return this.getEventsForDesign7();

      case 8:
        return this.getEventsForDesign8();

      case 9:
        return this.getEventsForDesign9();

      case 10:
        return this.getEventsForDesign10();

      case 11:
        return this.getEventsForDesign11();

      case 12:
        return this.getEventsForDesign12();

      case 13:
        return this.getEventsForDesign13();

      case 14:
        return this.getEventsForDesign14();
    }

  }

  getEventsForDesign1(): any {
    var that = this;
    return {
      'onFavorite': function (item: any) {
        item.isFavorite = !item.isFavorite
      },
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.avatarTitle);
      },
      'onComment': function () {
        that.toastCtrl.presentToast('Comment');
      }
    };
  }

  getEventsForDesign2(): any {
    var that = this;
    return {
      'onComment': function () {
        that.toastCtrl.presentToast('Comment');
      }
    };
  }

  getEventsForDesign3(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      }
    };
  }

  getEventsForDesign4(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      }
    };
  }

  getEventsForDesign5(): any {
    var that = this;
    return {
      'onItemClickFeed': function (item: any) {
        that.toastCtrl.presentToast(item.feedTitle);
      },
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      },
    };
  }

  getEventsForDesign6(): any {
    var that = this;
    return {
      'onWriteComment': function (item: any) {
        that.toastCtrl.presentToast('Write Comment');
      }
    };
  }

  getEventsForDesign7(): any {
    var that = this;
    return {
      'onFavorite': function (item: any) {
        item.isFavorite = !item.isFavorite
      },
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.avatarTitle);
      },
    };
  }

  getEventsForDesign8(): any {
    var that = this;
    return {
      'onWriteComment': function (item: any) {
        that.toastCtrl.presentToast('Write Comment');
      }
    };
  }

  getEventsForDesign9(): any {
    var that = this;
    return {
      'onWriteComment': function (item: any) {
        that.toastCtrl.presentToast('Write Comment');
      }
    };
  }

  getEventsForDesign10(): any {
    return {

    };
  }

  getEventsForDesign11(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      }
    };
  }

  getEventsForDesign12(): any {
    var that = this;
    return {
      'onItemClick': function (item: any) {
        that.toastCtrl.presentToast(item.title);
      }
    };
  }

  getEventsForDesign13(): any {
    return {

    };
  }

  getEventsForDesign14(): any {
    return {

    };
  }

}
