import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(private toastController: ToastController) { }

  async presentToast(message: string) {
    const toast = await this
      .toastController
      .create({
        'position': 'top',
        'duration': 1000,
        'message': message
      });
    toast.present();
  }
}
