import { Injectable } from '@angular/core';
import { IService } from './IService';
import { MenuItem } from './MenuItem';
import { Constants } from './constants';
import { ToastService } from './toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class WalkthroughService implements IService {

    constructor(private toastCtrl: ToastService, private loadingService: LoadingService, public af: AngularFireDatabase) { }

    getMenuList(): MenuItem[] {
        var menuItemList = [];
        menuItemList.push(new MenuItem(1, 'Walkthrough', 0, Constants.WALKTHROUGHT));
        return menuItemList;
    }

    load(menuItem: MenuItem): Observable<any> {
        var that = this;
        that.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('walkthrought/layout' + menuItem.id)
                    .valueChanges()
                    .subscribe(snapshot => {
                        that.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        that.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                that.loadingService.hide();
                observer.next(this.getDataByMenuId(menuItem));
                observer.complete();
            });
        }
    }

    loadEvents(menuItem: MenuItem) {
        return this.geEventsByMenuId(menuItem);
    }

    getDataByMenuId(menuItem: MenuItem) {
        switch (menuItem.id) {
            case 1:
                return this.getDataForDesignOne();
        }

    }

    geEventsByMenuId(menuItem: MenuItem): any {

        switch (menuItem.id) {
            case 1:
                return this.getEventsForDesignOne();
        }
    }
    // DATA FOR WALKTHROUGHT
    getDataForDesignOne(): any {
        return {
            'headerTitle': 'Wizard',
            'btnPrev': 'Previous',
            'btnNext': 'Next',
            'btnFinish': 'Finish',
            'getStarted': 'Get Started',
            'items': [
                {
                    'logo': 'assets/imgs/wizard/4.jpg',
                    'backgroundImage': 'assets/imgs/wizard/1.jpg',
                    'title': 'Modern Design',
                    'description': 'All screens and components are compatible with Photoshop & Sketch',
                },
                {
                    'logo': 'assets/imgs/wizard/5.jpg',
                    'backgroundImage': 'assets/imgs/wizard/2.jpg',
                    'title': 'Clean Code',
                    'description': 'Choose your technology, you get Ionic 3 or Native Android code',
                },
                {
                    'logo': 'assets/imgs/wizard/6.jpg',
                    'backgroundImage': 'assets/imgs/wizard/3.jpg',
                    'title': 'Unleash Creativity',
                    'description': 'With more than 45 screens you can build any app you imagine',
                }
            ]
        }
    }

    getEventsForDesignOne(): any {
        var that = this;
        return {
            'onFinish': function (event: any) {
                that.toastCtrl.presentToast('Finish');
            },
            'onGetStarted': function (event: any) {
                that.toastCtrl.presentToast('Get Started');
            }
        }
    }
}
