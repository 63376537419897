import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavController } from '@ionic/angular';
import { MenuItem } from './services/MenuItem';
import { Constants } from './services/constants';
import { ExportService } from './services/export';
import { CacheService } from './services/cachedata.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [ExportService]
})
export class AppComponent {
  pages = [];
  sponsorlist ={
    'items': [
      {
        'id': 1,
        'images': 'assets/imgs/wizard/meowprotectionbanner.jpeg',
        'dataTime': 'March 14th, 2019'
        // url:'https://www.facebook.com/The-Official-Site-of-Kelab-Kucing-Malaysia-KKM-120334194673199'
      },

      {
        'id': 2,
        'images': 'assets/imgs/wizard/programpartnerbanner7.png',
        'title': '',
        'dataTime': 'August 25th, 2019',
        url:'https://www.facebook.com/kopstar.my/'

      
      }
    ]
  }
  partnerList = {
    'items': [
      {
        'id': 3,
        'images': 'assets/imgs/wizard/programpartner3.png',
        'title': '',
        'dataTime': 'April 17th, 2019',
        url:'https://www.facebook.com/MediaFocusMarketingSdnBhd/'

     
      }
    ]
     
  }
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navController: NavController,
    private exportService: ExportService,
    private cacheservice:CacheService
  ) {
    this.cacheservice.setuserData();
    this.initializeApp();
  }

  openBrowserFx(item){
    window.open(item.url,'_blank')
  }


  // getMenuList(): MenuItem[] {
  //   var menuItemList = [];
  //   menuItemList.push(new MenuItem(1, Constants.WALKTHROUGHT, 1, Constants.WALKTHROUGHT));
  //   menuItemList.push(new MenuItem(2, Constants.SIGNUP, 6, Constants.SIGNUP));
  //   menuItemList.push(new MenuItem(3, Constants.SOCIAL, 14, Constants.SOCIAL));
  //   menuItemList.push(new MenuItem(4, Constants.PROFILE, 7, Constants.PROFILE));
  //   menuItemList.push(new MenuItem(5, Constants.MEDIA, 4, Constants.MEDIA));
  //   menuItemList.push(new MenuItem(6, Constants.E_COMMERCE, 13, Constants.E_COMMERCE));
  //   menuItemList.push(new MenuItem(7, Constants.CHECK_BUTTON, 6, Constants.CHECK_BUTTON));
  //   menuItemList.push(new MenuItem(8, Constants.TOGGLE_BUTTON, 6, Constants.TOGGLE_BUTTON));
  //   menuItemList.push(new MenuItem(9, Constants.RADIO_BUTTON, 6, Constants.RADIO_BUTTON));
  //   menuItemList.push(new MenuItem(10, Constants.LISTS, 4, Constants.LISTS));
  //   menuItemList.push(new MenuItem(11, Constants.OTHER, 4, Constants.OTHER));
  //   menuItemList.push(new MenuItem(12, Constants.RANGE, 4, Constants.RANGE));
  //   menuItemList.push(new MenuItem(13, Constants.TAB, 4, Constants.TAB));
  //   return menuItemList;
  // }

  initializeApp() {
    // console.log(JSON.stringify(this.exportService.export()))
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#0091D2');
  //    this.pages = this.getMenuList();
    });

  
  }

  openPage(page: any) {
    this.navController.navigateRoot([`items/${page.type}`], {});
  }
}
