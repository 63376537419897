import { HomeService } from './home-service';
import { WalkthroughService } from './walkthrough-service';
import { SocialService } from './social-service';
import { SignUpService } from './signup-service';
import { ProfileService } from './profile-service';
import { MediaService } from './media-service';
import { EcommerceService } from './ecommerce-service';

import { Injectable } from '@angular/core';

@Injectable()
export class ExportService{
    export() {
        return {
            'home': this.getHome(),
            'walkthrought': this.getWalkthrough(),
            'social': this.getSocial(),
            'signup': this.getSignUp(),
            'profile': this.getProfile(),
            'media': this.getMedia(),
            'ecommerce': this.getEcommerce()
        }
    }

    getHome() {
        let service = new HomeService(null, null);
        return service.getHomeData()
    }

    getWalkthrough() {
        let service = new WalkthroughService(null, null, null);
        return {
            'layout1': service.getDataForDesignOne(),
        }
    }

    getSocial() {
        let service = new SocialService(null, null, null);
        return {
            'layout1': service.getDataForDesign1(),
            'layout2': service.getDataForDesign2(),
            'layout3': service.getDataForDesign3(),
            'layout4': service.getDataForDesign4(),
            'layout5': service.getDataForDesign5(),
            'layout6': service.getDataForDesign6(),
            'layout7': service.getDataForDesign7(),
            'layout8': service.getDataForDesign8(),
            'layout9': service.getDataForDesign9(),
            'layout10': service.getDataForDesign10(),        }
    }

    getSignUp() {
        let service = new SignUpService(null, null, null);
        return {
            'layout1': service.getDataForLayout1(),
            'layout2': service.getDataForLayout2(),
            'layout3': service.getDataForLayout3(),
        }
    }

    getProfile() {
        let service = new ProfileService(null, null, null);
        return {
            'layout1': service.getDataForDesign1(),
            'layout2': service.getDataForDesign2(),
            'layout3': service.getDataForDesign3(),
            'layout4': service.getDataForDesign4(),
            'layout5': service.getDataForDesign5()
        }
    }

    getMedia() {
        let service = new MediaService(null, null, null);
        return {
            'layout1': service.getDataForDesign1(),
            'layout2': service.getDataForDesign2(),
            'layout3': service.getDataForDesign3(),
            'layout4': service.getDataForDesign4(),
        }
    }
    getEcommerce() {
        let service = new EcommerceService(null, null, null);
        return {
            'layout1': service.getDataForDesign1(),
            'layout2': service.getDataForDesign2(),
            'layout3': service.getDataForDesign3(),
            'layout4': service.getDataForDesign4(),
            'layout5': service.getDataForDesign5(),
            'layout6': service.getDataForDesign6(),
            'layout7': service.getDataForDesign7(),
            'layout8': service.getDataForDesign8(),
            'layout9': service.getDataForDesign9(),
            'layout10': service.getDataForDesign10(),
        }
    }
}
