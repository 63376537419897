export const AppSettings = {
    "IS_FIREBASE_ENABLED": false,
    "TOAST": {
        "duration": 1000,
        "position": "buttom"
    },
    "FIREBASE_CONFIG": {
        "apiKey": "AIzaSyAUJxrOJvrDKh0MVP80i8hdH0mN9k0V5Vs",
        "authDomain": "ionic-deco-design.firebaseapp.com",
        "databaseURL": "https://ionic-deco-design.firebaseio.com",
        "projectId": "ionic-deco-design",
        "storageBucket": "ionic-deco-design.appspot.com",
        "messagingSenderId": "494679492035"
    }
};
