import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';


@Injectable({ providedIn: 'root' })
export class CacheService {
  public logindata: any
  public account_id: any = 0
  public post_content_Id: any
  public role_level_id:any
  categoryidFromMain: any = 0;
  defaultPage: any ='corepage/mainpage';
  choosenProgrammeFromMain: any;
  choosenprogramForPosting: any;
  constructor(private storage: Storage) { }

  savetolocal(dataname, data) {
    this.storage.set(dataname, data);


  }

 async setuserData() {
 await   this.storage.get('logindata').then((val) => {
      if(val){
        this.setuplocalDataFx(val)
   
      }
  

    });
  }

  setuplocalDataFx(val){
    console.log('setuplocalDataFx',val);

    this.logindata = val
    this.account_id = val.return_set_01_data.account_id
    this.role_level_id = val.return_set_01_data.role_level_id

    console.log('this.account_id', this.account_id);
  }

  cleardata() {
    this.logindata = null
    this.account_id = 0
    this.role_level_id = null


  }
}
