import { Injectable } from '@angular/core';
import { IService } from './IService';
import { MenuItem } from './MenuItem';
import { Constants } from './constants';
import { ToastService } from './toast-service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class ProfileService implements IService {

  constructor(public af: AngularFireDatabase, private loadingService: LoadingService, private toastCtrl: ToastService) { }

  getMenuList(): MenuItem[] {
    var menuItemList = [];
    menuItemList.push(new MenuItem(1, "Avatar", 0, Constants.PROFILE));
    menuItemList.push(new MenuItem(2, "Cover Image", 0, Constants.PROFILE));
    menuItemList.push(new MenuItem(3, "Cards", 0, Constants.PROFILE));
    menuItemList.push(new MenuItem(4, "Category", 0, Constants.PROFILE));
    menuItemList.push(new MenuItem(5, "Blocks", 0, Constants.PROFILE));
    menuItemList.push(new MenuItem(6, "Small Avatar", 0, Constants.PROFILE));
    menuItemList.push(new MenuItem(7, "Profile / Edit", 0, Constants.PROFILE));
    return menuItemList;
  }

  // DATA FOR PROFILE --- AVATAR
  getDataForDesign1 = (): any => {
    return {
      "headerTitle": "Profile-Avatar",
      "profileImages": "assets/imgs/profile/3.jpg",
      "title": "Emilie Møller",
      "subtitle": "Denmark, Copenhagen",
      "buttonText": "Following",
      "items": [
        {
          "id": 1,
          "number": 394,
          "detail": "images"
        },
        {
          "id": 2,
          "number": 2842,
          "detail": "followers"
        },
        {
          "id": 3,
          "number": 723,
          "detail": "General Electric"
        }
      ]
    };
  }


  // DATA FOR PROFILE --- COVER IMAGE
  getDataForDesign2 = (): any => {
    return {
      "headerTitle": "Profile- Cover Image",
      "headerImage": "assets/imgs/content/14.jpg",
      "title": "Elias Sørensen",
      "subtitle": "Designer @ GlobeTech Plus, San Francisco",
      "buttonText": "Following",
      "buttonIcon": "chatbubbles",
      "items": [
        {
          "id": 1,
          "number": 394,
          "detail": "images"
        },
        {
          "id": 2,
          "number": 2842,
          "detail": "followers"
        },
        {
          "id": 3,
          "number": 723,
          "detail": "following"
        }
      ],
      /* Gallery */
      "gallery": [
        "assets/imgs/content/1.jpg",
        "assets/imgs/content/2.jpg",
        "assets/imgs/content/3.jpg",
        "assets/imgs/content/4.jpg",
        "assets/imgs/content/5.jpg",
        "assets/imgs/content/6.jpg",
        "assets/imgs/content/7.jpg",
        "assets/imgs/content/8.jpg",
        "assets/imgs/content/9.jpg"
      ]
    };
  }

  // DATA FOR PROFILE --- CARDS
  getDataForDesign3 = (): any => {
    return {
      "headerTitle": "Profile Cards",
      "profileImages": "assets/imgs/profile/3.jpg",
      "title": "Emilie Møller",
      "subtitle": "Denmark, Copenhagen",
      "buttonTextFollowin": "Followin",
      "buttonTextMassage": "Message",
      "items": [
        {
          "id": 1,
          "number": 394,
          "detail": "images"
        },
        {
          "id": 2,
          "number": 2842,
          "detail": "followers"
        },
        {
          "id": 3,
          "number": 723,
          "detail": "following"
        }
      ],

      "itemsList": [
        {
          "id": 1,
          "imagesList": "assets/imgs/content/1.jpg",
          "itemListTitle": "Orange Beets",
          "itemListSubtitle": "You take the blue pill - the story ends, you wake up in your bed.",
          "time": "March 14th, 2019",
        },
        {
          "id": 2,
          "imagesList": "assets/imgs/content/2.jpg",
          "itemListTitle": "Music Family",
          "itemListSubtitle": "And believe whatever you want to believe. You take the red pill.",
          "time": "March 19th, 2019"
        },
        {
          "id": 3,
          "imagesList": "assets/imgs/content/3.jpg",
          "itemListTitle": "Square is Green",
          "itemListSubtitle": "You stay in Wonderland and I show you how deep the rabbit-hole goes.",
          "time": "April 27th, 2019",
        }
      ]
    };
  }

  // DATA FOR PROFILE --- CATEGORY
  getDataForDesign4 = (): any => {
    return {
      "headerTitle": "Profile Category",
      "profileImages": "assets/imgs/profile/3.jpg",
      "profiletitle": "Emilie Møller",
      "profilesubtitle": "Denmark, Copenhagen",
      "imagesGalleryTitle": "Images",
      "imagesGallery": [
        "assets/imgs/content/1.jpg",
        "assets/imgs/content/2.jpg",
        "assets/imgs/content/3.jpg"
      ],

      "nameCategory": "Blog post",

      "catrgoryList": [
        {
          "id": 1,
          "imagesList": "assets/imgs/content/1.jpg",
          "itemListTitle": "Orange Beets",
          "itemListSubtitle": "You take the blue pill - the story ends, you wake up in your bed.",
          "dataTime": "MARCH 14th, 2018"
        },
        {
          "id": 2,
          "imagesList": "assets/imgs/content/2.jpg",
          "itemListTitle": "Music Family",
          "itemListSubtitle": "And believe whatever you want to believe. You take the red pill.",
          "dataTime": "MARCH 12th, 2018"
        },
        {
          "id": 3,
          "imagesList": "assets/imgs/content/3.jpg",
          "itemListTitle": "Square is Green",
          "itemListSubtitle": "You stay in Wonderland and I show you how deep the rabbit-hole goes.",
          "dataTime": "MARCH 10th, 2018"
        }
      ]
    };
  }

  // DATA FOR PROFILE --- BLOCKS
  getDataForDesign5 = (): any => {
    return {
      "headerTitle": "Block",
      "avatra": "assets/imgs/avatar/1.jpg",
      "avatraTitle": "Laura Olsen",
      "avatraSubtitle": "Graphic Designe",
      "bigTitle": "Statistics",
      "list": [
        {
          "id": 1,
          "icon": "heart",
          "nameIcon": "Orange Beets",
          "valueIcon": "9320 likes"
        },
        {
          "id": 2,
          "icon": "chatbubbles",
          "nameIcon": "Comments",
          "valueIcon": "442 likes"
        },
        {
          "id": 3,
          "icon": "image",
          "nameIcon": "Images",
          "valueIcon": "842 Images"
        },
        {
          "id": 4,
          "icon": "add-circle",
          "nameIcon": "Add more"
        }
      ]
    }
  }

  // DATA FOR PROFILE --- COVER IMAGE
  getDataForDesign6 = (): any => {
    return {
      "headerTitle": "Profile- Small Avatar",
      "avatar": "assets/imgs/avatar/1.jpg",
      "title": "Eric Latero",
      "subtitle": "Photographer / Artist",
      "description": "Hi, my name is Eric and I love photography! It is my greatest passion in life",
      "buttonFollow": "Follow",
      "buttonMessage": "Message",
      "latestWork": "My latest works",
      "viewAll": "View All",
      "items": [
        {
          "id": 1,
          "number": 394,
          "detail": "images"
        },
        {
          "id": 2,
          "number": 2842,
          "detail": "followers"
        },
        {
          "id": 3,
          "number": 723,
          "detail": "following"
        }
      ],
      /* Gallery */
      "gallery": [
        "assets/imgs/content/1.jpg",
        "assets/imgs/content/2.jpg",
        "assets/imgs/content/3.jpg",
        "assets/imgs/content/4.jpg",
        "assets/imgs/content/5.jpg",
        "assets/imgs/content/6.jpg",
        "assets/imgs/content/7.jpg",
        "assets/imgs/content/8.jpg",
        "assets/imgs/content/9.jpg"
      ]
    };
  }

  // DATA FOR PROFILE --- COVER IMAGE
  getDataForDesign7 = (): any => {
    return {
      "headerTitle": "Profile- Edit",
      "avatar": "assets/imgs/avatar/1.jpg",
      "button": "Edit profile",
      "items": [
        {
          "id": 1,
          "title": 'Eric Latero',
          "icon": "person"
        },
        {
          "id": 1,
          "title": '30 July 2001t',
          "icon": "calendar"
        },
        {
          "id": 2,
          "title": '+321 456 987',
          "icon": "call"
        },
        {
          "id": 3,
          "title": 'eric.latero@wonderland.com',
          "icon": "mail"
        },
        {
          "id": 4,
          "title": 'Password',
          "icon": "lock-closed",
        }
      ],

    };
  }

  load(menuItem: MenuItem): Observable<any> {
    var that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('profile/layout' + menuItem.id)
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getDataByMenuId(menuItem));
        observer.complete();
      });
    }
  }

  loadEvents(menuItem: MenuItem) {
    return this.geEventsByMenuId(menuItem);
  }

  getDataByMenuId(menuItem: MenuItem) {
    switch (menuItem.id) {
      case 1:
        return this.getDataForDesign1();

      case 2:
        return this.getDataForDesign2();

      case 3:
        return this.getDataForDesign3();

      case 4:
        return this.getDataForDesign4();

      case 5:
        return this.getDataForDesign5();

      case 6:
        return this.getDataForDesign6();

      case 7:
        return this.getDataForDesign7();
    }

  }

  geEventsByMenuId(menuItem: MenuItem): any {

    switch (menuItem.id) {
      case 1:
        return this.getEventsForDesign1();

      case 2:
        return this.getEventsForDesign2();

      case 3:
        return this.getEventsForDesign3();

      case 4:
        return this.getEventsForDesign4();

      case 5:
        return this.getEventsForDesign5();

      case 6:
        return this.getEventsForDesign6();

      case 7:
        return this.getEventsForDesign7();
    }
  }


  getEventsForDesign1(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast('Following');
      }
    };
  }


  getEventsForDesign2(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast('Following');
      },
      "onComment": function (item: any) {
        that.toastCtrl.presentToast('Comment');
      }
    };
  }

  getEventsForDesign3(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast('Following');
      },
      "onMessage": function (item: any) {
        that.toastCtrl.presentToast('Message');
      },
      "onProductItemClick": function (item: any) {
        that.toastCtrl.presentToast(item.itemListTitle);
      },
    };
  }

  getEventsForDesign4(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast(item.itemListTitle);
      }
    };
  }

  getEventsForDesign5(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast(item.nameIcon);
      }
    };
  }

  getEventsForDesign6(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast('Following');
      },
      "onComment": function (item: any) {
        that.toastCtrl.presentToast('Comment');
      }
    };
  }

  getEventsForDesign7(): any {
    var that = this;
    return {
      "onItemClick": function (item: any) {
        that.toastCtrl.presentToast('Following');
      },
      "onComment": function (item: any) {
        that.toastCtrl.presentToast('Comment');
      }
    };
  }
}
